@import '../../../assets/sass/variables.scss';

.accountaccess-box {
  background-color: $blue;
  padding: 55px 0;
  .container {
    @media (max-width: 1200px) {
      max-width: 670px;
    }
    @media (max-width: 991px) {
      max-width: 640px;
    }
  }
  .content-box {
    @media (max-width: 1200px) {
      max-width: 60%;
      flex: 1;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      flex: 1;
    }
    .inner-box {
      max-width: 470px;
      margin-left: auto;
      @media (max-width: 767px) {
        max-width: 290px;
        margin: auto;
      }
    }
    p {
      font-size: 1.25em;
      line-height: 1.4;
      @media (max-width: 1200px) {
        font-size: 18px;
        line-height: 1.45;
      }
      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .button-box {
    .inner-box {
      display: flex;
      align-items: center;
      max-width: 350px;
      margin: 0 auto;
      height: 100%;
      @media (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-end;
      }
      @media (max-width: 767px) {
        align-items: center;
      }
    }
    .button {
      &.button-outline {
        &:hover {
          background-color: $white;
          color: $blue;
          border-color: $white;
        }
      }
      &.button-solid {
        background-color: $white;
        color: $blue;
        border-color: $white;
        &:hover {
          background-color: $buttonsolidhover;
          color: $white;
          border-color: $buttonsolidhover;
        }
      }
      @media (max-width: 1200px) {
        min-width: 180px;
        + .button {
          margin-left: 0;
          margin-top: 10px;
        }
      }
      @media (max-width: 767px) {
        min-width: 230px;
      }
    }
  }
}

.site-footer {
  position: relative;
  z-index: 1;
  background-color: $black;
  .bottom-info {
    padding: 80px 0;
    @media (max-width: 991px) {
      padding: 60px 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: 0;
        margin-bottom: 18px;
      }
    }
    .info-box {
      .inner-box {
        max-width: 560px;
        margin-top: -5px;
        color: #cecece;

        @media (max-width: 767px) {
          margin-bottom: 50px;
          max-width: 100%;
        }
      }
      p {
        font-size: 14px;
        line-height: 1.7;
        color: #cecece;

        @media (max-width: 991px) {
          font-size: 12px;
        }
      }
      .app-button {
        // display: flex;
        margin-top: 50px;
        // a {
        //   display: block;
        //   width: 100%;
        //   max-width: 200px;
        //   img {
        //     width: 100%;
        //   }
        //   + a {
        //     margin-left: 15px;
        //   }
        // }
        @media (max-width: 991px) {
          margin-top: 30px;
        }
      }
    }
    .links-box {
      .inner-box {
        padding-left: 50px;
        @media (max-width: 991px) {
          padding-left: 0;
          font-size: 14px;
        }
      }

      @media (max-width: 360px) {
        max-width: 100%;
        flex: 100%;
        margin-bottom: 20px;
      }
    }
    .contact-box {
      @media (max-width: 991px) {
        font-size: 14px;
      }
      .contact-us-link {
        color: #f2f2f2;
        font-weight: 600;
        margin-top: -5px;
        &:hover {
          text-decoration: none;
          color: $blue;
        }
      }
      dl {
        color: #f2f2f2;
        @media (max-width: 991px) {
          font-size: 14px;
        }
        dt {
          margin-bottom: 10px;
        }
        dd {
          margin-left: 0;
          margin-bottom: 18px;
        }
      }
      .sociallist {
        a {
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 12px;
          position: relative;
          &:last-child {
            margin-right: 0;
          }
          img {
            position: absolute;
            left: 50%;
            top: 50%;
            opacity: 1;
            transform: translate(-50%, -50%);
            transition: all 0.6s ease-out;
            &.hover {
              opacity: 0;
            }
          }
          &:hover {
            img {
              opacity: 0;
              &.hover {
                opacity: 1;
              }
            }
          }
        }
        .icon {
          width: 20px;
          height: inherit;
        }
      }
    }
  }
}
