@import "../../../assets/sass/variables.scss";

.menu-box {
	margin: auto;
	display: flex;
	flex: 1;
	align-items: center;

	ul {
		li {
			padding: 0;
		}
	}
	.menu {
		display: flex;
		margin: 0 auto;
		justify-content: space-between;
		width: 70%;
		position: relative;
		@media (max-width: 1199px) {
			width: 80%;
		}
		> li {
			> a {
				color: $white;
				display: flex;
				align-items: center;
				.icon {
					width: 12px;
					height: auto;
					margin-left: 10px;
					transition: all 0.6s;
				}
				&:hover {
					text-decoration: none;
				}
			}
			&.has-child {
				&.active {
					.icon {
						transform: rotate(180deg);
					}
					> .submenu {
						pointer-events: fill;
						opacity: 1;
						visibility: visible;
						transition-delay: 0.5s;
					}
				}
			}
		}
		.submenu {
			position: absolute;
			top: 100%;
			width: 200px;
			z-index: 2;
			padding: 20px 0 0;
			pointer-events: none;
			opacity: 0;
			visibility: hidden;
			transition: 0.2s ease-out;
			transition-delay: 0s;
			z-index: 100;

			li {
				// padding: 8px 0px;
				margin-bottom: 12px;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					color: $blue;
					transition: all 275ms ease-out;
					text-decoration: none;
					position: relative;
					display: inline-block;
					// border: 1px solid red;
					&:before {
						background-color: $white;
						top: 50%;
						transform: translateY(-50%);
						height: 80%;
						content: "";
						left: -15px;
						position: absolute;
						width: 1px;
						opacity: 0;
						// transition : all .6s ease-out;
						transition: all 0.6s ease-out;
					}
					&:hover {
						color: $white;
						text-decoration: none;
						&:before {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	@media (max-width: 991px) {
		display: none;
	}
}

.siteHeader {
	// position: relative;
	padding: 20px 0;
	background-color: $black;
	z-index: 999;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	.row-wrap {
		margin: 0;
	}
	@media (max-width: 1199px) {
		font-size: 12px;
	}
	@media (max-width: 991px) {
		font-size: 14px;
	}
	@media (max-width: 991px) {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		box-shadow: 0px 1px 0px #9d9d9d33;
	}
	.row-wrap {
		align-items: center;
		@media (max-width: 991px) {
			align-items: flex-start;
		}
		@media (max-width: 767px) {
			margin: 0;
		}
	}
	.logo-box {
		img {
			width: 170px;
		}
		.for-mobile {
			display: none;
		}
		@media (max-width: 991px) {
			display: flex;
			margin-right: auto;
			width: 100%;
			max-width: 200px;
			.logo {
				max-width: 150px;
				@media (max-width: 350px) {
					max-width: 115px;
				}
			}
			.for-desktop {
				display: none;
			}
			.for-mobile {
				display: block;
				width: 100%;
				.main-root {
					background-color: $black;
					box-shadow: none;
					margin-top: 8px;
					margin-bottom: 7px;
					.Mui-expanded {
						margin: 0;
					}
					// #panel1d-header{}

					.MuiPaper-root {
						background-color: $black;
					}
					.MuiAccordionSummary-root {
						padding: 0;
					}

					.main-summary {
						min-height: inherit;
						&.Mui-expanded {
							min-height: inherit;
						}
						> .MuiAccordionSummary-content {
							margin: 0;
							width: 180px;
							+ .MuiIconButton-root {
								transform: none;
								margin-right: 0 !important;
								padding: 0;
							}
						}
					}

					.MuiIconButton-root {
						color: $white;
						.icon {
							width: 12px;
							height: 12px;
						}
					}
					.main-content {
						flex-direction: column;
						padding: 0px 25px 13px;
						max-height: calc(100vh - 100px);
						overflow-y: auto;
						margin-top: 40px;
						@media (max-width: 767px) {
							padding: 0px 20px 13px;
							margin-top: 30px;
						}
						.sub-root {
							box-shadow: none;
							color: $white;
							padding: 8px 0;
							> .MuiAccordionSummary-root {
								min-height: inherit;
								justify-content: flex-start;
								> .MuiAccordionSummary-content {
									margin: 0;
									// flex: 0;
									flex: inherit;
								}
							}
							.MuiAccordionSummary-expandIcon {
								margin-right: 0;
								padding: 0;
								margin-left: 10px;
								&.Mui-expanded {
									margin-left: 10px;
								}
							}
							.MuiCollapse-container {
								.MuiAccordionDetails-root {
									padding: 10px 0;
								}
							}
						}
					}
				}
				.navList {
					li {
						margin-bottom: 8px;
					}
					a {
						color: $blue;
						transition: all 275ms ease-out;
						text-decoration: none;
						position: relative;
						display: inline-block;
						// border: 1px solid red;
						&:before {
							background-color: $white;
							top: 50%;
							transform: translateY(-50%);
							height: 80%;
							content: "";
							left: -15px;
							position: absolute;
							width: 1px;
							opacity: 0;
							// @extend %transition;
						}
						&:hover {
							color: $white;
							&:before {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		@media (max-width: 350px) {
			max-width: 160px;
		}
		a {
			display: inline-block;
		}
		.hamburger {
			display: none;
			@media (max-width: 991px) {
				display: inline-block;
				margin-left: auto;
				display: flex;
				align-items: center;
				.hamburger-box {
					width: 30px;
					height: 24px;
					// border: 1px solid red;
					position: relative;
					-webkit-transform: rotate(0deg);
					-moz-transform: rotate(0deg);
					-o-transform: rotate(0deg);
					transform: rotate(0deg);
					-webkit-transition: 0.5s ease-in-out;
					-moz-transition: 0.5s ease-in-out;
					-o-transition: 0.5s ease-in-out;
					transition: 0.5s ease-in-out;
					cursor: pointer;
					span {
						display: block;
						position: absolute;
						height: 2px;
						width: 100%;
						background: #3d3d3d;
						// border-radius: 9px;
						opacity: 1;
						left: 0;
						-webkit-transform: rotate(0deg);
						-moz-transform: rotate(0deg);
						-o-transform: rotate(0deg);
						transform: rotate(0deg);
						-webkit-transition: 0.25s ease-in-out;
						-moz-transition: 0.25s ease-in-out;
						-o-transition: 0.25s ease-in-out;
						transition: 0.25s ease-in-out;

						&:nth-child(1) {
							top: 0px;
						}

						&:nth-child(2),
						&:nth-child(3) {
							top: 10px;
						}
						&:nth-child(3) {
							background: transparent;
						}

						&:nth-child(4) {
							top: 20px;
						}
					}
					&.open span:nth-child(1) {
						top: 18px;
						width: 0%;
						left: 50%;
					}

					&.open span:nth-child(2) {
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						transform: rotate(45deg);
					}

					&.open span:nth-child(3) {
						-webkit-transform: rotate(-45deg);
						-moz-transform: rotate(-45deg);
						-o-transform: rotate(-45deg);
						transform: rotate(-45deg);
						background: #3d3d3d;
					}

					&.open span:nth-child(4) {
						top: 18px;
						width: 0%;
						left: 50%;
					}
				}
			}
			@media (max-width: 767px) {
				margin-left: auto;
			}
		}
	}
	.header-buttons-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10px;
		@media (max-width: 595px) {
			margin-right: 2vw;
			a, .language-dropdown-wrapper {
				margin-top: (40px - $header-icon-size) / 2;
			}
			// not showing buttons on mobile screen
			.button {
				display: none;
			}
		}
		.header-button {
			display: none;
			@media (max-width: 595px) {
				display: block;
				width: $header-icon-size;
				height: $header-icon-size;
			}
			&.sign-in {
				background-image: url($sign_in_icon_url);
			}
			&.sign-up {
				background-image: url($sign_up_icon_url);
			}
		}
	}

	.button-solid {
		// mainStyle has .button + .button marginLeft which is fine for horizontal but not vertical align like in this case
		margin-left: 0;
	}
}

.menu-box {
	&.for-mobile {
		@media (min-width: 992px) {
			display: none;
		}
		display: block;
		position: relative;
		left: 0;
		width: 100%;
		// background-color: $lightdark;
		padding: 10px 20px;
		z-index: 100;

		.container {
			padding: 0;
		}
		.menu {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding-bottom: 10px;
			> li {
				> a {
					padding: 10px 0;
				}
			}
			.submenu {
				display: none;
				position: relative;
				opacity: 1;
				width: 100%;
				visibility: visible;
				padding-top: 0;
				padding-bottom: 15px;
			}
		}
	}
}
.headerclone {
	width: 100%;
}
#menu-open {
	position: fixed;
	left: 0;
	min-height: 1px;
	width: 100%;
	background-color: $black;
	z-index: 99;
	box-shadow: 0px 1px 0px #9d9d9d33;
	overflow: hidden;
	// top: 80px;
	// animation: fade 250ms 700ms iteration-count direction fill-mode;
	@media (max-width: 991px) {
		display: none;
	}
}
