@import "../../../assets/sass/variables.scss";

.language-dropdown-wrapper {
    background-image: url($language_select_icon_url);
    background-repeat: no-repeat;
    width: $header-icon-size;
    height: $header-icon-size;
}

.language-select {
    position: relative;
    background-color: $black;
    left: -60px;
    width: fit-content;
    padding: 15px;
    font-size: 14px;
    border: 1px #9d9d9d33 solid;
    .active {
        color: $bluehover;
    }
    span {
        display: block;
        cursor: pointer;
    }
    span + span {
        margin-top: 10px;
    }
}

.language-arrow-up {
    display: block;
    width: 0; 
    height: 0; 
    margin-top: 36px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid #9d9d9d33;
}