.loadingWrap{
	display: flex;
	height: 100%;
	position: absolute;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.loading-text {
	display: block;
	width: 100%;
	margin-top: 20px;
	text-align: center;
	letter-spacing: 3px;
}