$black: #111111;
$white: #ffffff;
$gray: #e5e5e5;
$yellow: #ffe17d;

$blue: #178bff;
$bluehover: #2677c8;
$lightdark: #292929;

$darkline: #212121;

// button variable
$buttonsolid: $blue;
$buttonsolidhover: $bluehover;

//card variable
$cardbg: $gray;

// Header icon size (sign-in, sign-up, language select)
$header-icon-size: 28px;
$sign_up_icon_url: "/assets/images/Demivolt_icon_create_account.svg";
$sign_in_icon_url: "/assets/images/Demivolt_icon_sign_in.svg";
$language_select_icon_url: "/assets/images/Demivolt_icon_language.svg";
